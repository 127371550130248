* {
  
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  
  margin: 1rem 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  background-color: rgb(216, 213, 213);
}

::-webkit-scrollbar {
  width: 0.6rem;
  background-color: #14213d;
}
::-webkit-scrollbar-thumb {
  background-color: #4539e6;
}
.five{
  background-image: url("./assets/pic1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  
}
button{
  padding:2px;
    cursor: pointer;
    border-radius: 7px;
    border: none;
    height: 27px;
    width: auto;
    background-color: #B76A44;
    color: #FFFFFF;
    font-size: 1rem;
    font-color: #FFFFFF;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    text-decoration:none;
   

}
input{
  border-radius: 8px;

}


#ten{
  width:1290px;
  height:540px;
 
  background-color: white;
  display: flex;
  flex-direction: row;
  border: 2px solid #876E61;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  background-image: url("./assets/image20.jpg");

      background-repeat: no-repeat;
      background-size:100% 100%;
  
}
/* #ten:hover{

    transform: translateX(0.4rem) translateY(-1rem);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
} */
#sixty{
    width:60%;
    height: 75%;
    /* background-color:white; */
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    /* position: absolute; */
    /* right: 25px; */
    /* top:30px; */
    /* color:#876E61; */
    /* background-color: #876A44; */
  padding: 30px;
    
}
#seventy{
  width:40%;
  background-color: green;
  /* margin: 20px; */
  background-image: url("./assets/logo1.jpg"); 
  background-repeat: no-repeat;
  background-size:200%;
  background-position:-280px;
  /* border-radius: 120px; */
 
}

@media screen and (min-width: 280px) and (max-width: 720px) {
  
  #ten{
    
      display: flex;
      flex-direction: column;
      
      width:100%;
      height:1180px;

      padding:none;
      margin:none;
      
      background-image: url("./assets/image19.jpg");

      background-repeat: no-repeat;
      background-size:100% 100%;
      /* background-blend-mode:screen; */
      /* opacity: 75%; */
      border-radius: 10px;
      /* color: white; */
  }
  #sixty{
    height:60%;
    width: 100%;
    /* background-color: red; */
    padding:none;
  }
  #seventy{
    width:100%;
    height: 380px;
  background-color: green;
  /* margin: 20px; */
  background-position: -160px;
  }
  .five{
    width:380px;
    height: 1500px;
    background-color: green;
    background-image: url("./assets/image18.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* position: absolute;
    left: 0%; */
    /* border: 2px solid green; */
    /* opacity: 50%; */
    margin: none;
    border-radius: 15px;
  }
 
  

}
@media screen and (min-width: 720px) and (max-width: 1080px) {
  
  #ten{
    background-color: green;

  }
}